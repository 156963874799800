<template>
  <div class=" mt-2">
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
        Admin Panel
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/user-role">
        Roles and Permissions
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/personal-settings">
        Personal Settings
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-card
      class="mb-0"
      :title="$t('settings.cpty_trader.titles.manage_cpty_trader')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                cle
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
            <b-col v-if="$Can('add_intermediary')" cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="success" @click="newTrader">
                  <span class="text-nowrap">
                    {{
                      $t("settings.cpty_trader.buttons.create_new_cpty_trader")
                    }}</span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <vue-good-table
          class="table-sm"
          :columns="tableColumns"
          :rows="allCptyTraders"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: filter,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
          }"
          :group-options="{
            enabled: true,
          }"
          :fixed-header="true"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.row.mode === 'span'" class="group-header">
              <span>{{ props.row.label }}</span>
            </span>

            <span v-else-if="props.column.field === 'id'">
              <span>{{ props.row.id }}</span>
            </span>

            <span v-else-if="props.column.field === 'Trader'">
              <span>{{ props.row.Trader }}</span>
            </span>

            <span v-else-if="props.column.field === 'Action'">
              <div class="mx-auto">
                <b-button
                  variant="gradient-warning"
                  class="btn-icon mr-1"
                  v-b-tooltip.hover.top="'Edit'"
                  @click="editCpty(props.row)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="gradient-danger"
                  class="btn-icon"
                  v-b-tooltip.hover.top="'Delete'"
                  @click="deleteCptyTrader(props.row)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="perPage"
                  :options="['50', '100', '250']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>

      {{ /*cpty form modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="cptyTraderModal"
        :title="$t('settings.cpty_trader.titles.cpty_trade_form')"
        @hidden="resetCptyModal"
        size="md"
        :ok-title="
          status == 'Create'
            ? $t('settings.cpty_trader.buttons.create')
            : $t('settings.cpty_trader.buttons.update')
        "
        :cancel-title="$t('settings.cpty_trader.buttons.cancel')"
        @ok="cptyTraderOkFunction"
        no-close-on-backdrop
      >
        <b-overlay :show="cptyloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="createValidation">
              <b-form>
                <b-row class="mt-2 mb-3">
                  <b-col cols="12" xl="12" md="12">
                    <!-- cpty Trader-->
                    <b-form-group
                      :label="$t('settings.cpty_trader.form.trader')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.cpty_trader.form.trader')"
                        rules="required"
                      >
                        <b-form-input
                          id="cpty_trader"
                          v-model="cptyTraderData.trader"
                          name="cpty_trader"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.cpty_trader.form.trader')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" xl="12" md="12">
                    <!-- counterparty traders -->
                    <b-form-group
                      :label="$t('settings.cpty_trader.form.counterparty')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.cpty_trader.form.counterparty')"
                        rules="required"
                      >
                        <v-select
                          v-model="cptyTraderData.counterparty"
                          :options="optCptyTraders"
                          :clearable="false"
                          :placeholder="
                            $t('settings.cpty_trader.form.select_counterparty')
                          "
                          :reduce="(val) => val.AccountName"
                          label="AccountName"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="cptyloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{
                  $t("settings.cpty_trader.messages.cpty_trader_create_message")
                }}
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** cpty create modal*/}}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allCptyTraders: [],
      cptyTraderModal: false,
      cptyloading: false,
      status: "",
      cptyTraderData: {
        counterparty: null,
        trader: null,
      },
      optCptyTraders: [],
      tableColumns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Trader",
          field: "Trader",
        },
        {
          label: "Counterparty",
          field: "Counterparty",
        },
        {
          label: "Action",
          field: "Action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [50, 100, 250],
      filter: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["authUser"]),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getTraders();
  },
  methods: {
    getTraders() {
      this.loading = true;
      store
        .dispatch("settings/allCounterpartyTraders")
        .then((res) => {
          const groupedData = this.groupByCounterparty(res.data);

          this.allCptyTraders = groupedData;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    groupByCounterparty(data) {
      const groupedData = data.reduce((acc, item) => {
        const counterparty = item.Counterparty;
        if (!acc[counterparty]) {
          acc[counterparty] = {
            mode: "span",
            label: counterparty,
            html: false,
            children: [],
          };
        }
        acc[counterparty].children.push({
          id: item.id,
          Trader: item.Trader,
          Counterparty: item.Counterparty,
          Action: "Action",
        });
        return acc;
      }, {});

      // Grupları tek bir dizide birleştiriyoruz
      const result = Object.values(groupedData);

      return result;
    },
    newTrader() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getCounterparties`)
          .then((res) => {
            this.optCptyTraders = res.data;
            this.cptyTraderModal = true;
            this.status = "Create";
          })
          .catch((error) => reject(error))
          .finally(() => {
            this.loading = false;
          });
      });
    },
    cptyTraderOkFunction(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createValidation.validate().then((success) => {
        const url =
          this.status == "Create"
            ? "createNewCounterpartyForTrader"
            : "updateCounterpartyForTrader";
        if (
          this.status == "Edit" &&
          this.oldTraderName == this.cptyTraderData.trader
        ) {
          this.wrongToastMessage(
            this.$t("settings.cpty_trader.messages.same_cpty_trader")
          );
          return;
        }
        if (success) {
          this.cptyloading = true;
          store
            .dispatch(`settings/${url}`, this.cptyTraderData)
            .then((res) => {
              if (res.data.info == "Pass") {
                if (this.status == "Create") {
                  this.successMessage(
                    this.$t(
                      "settings.cpty_trader.messages.cpty_trader_create_success"
                    )
                  );
                } else {
                  this.successMessage(
                    this.$t(
                      "settings.cpty_trader.messages.cpty_trader_update_success"
                    )
                  );
                }
                this.resetCptyModal();
                this.getTraders();
              } else {
                this.wrongToastMessage(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.cptyloading = false;
            });
        }
      });
    },
    resetCptyModal() {
      this.cptyTraderModal = false;
      this.cptyTraderData = {
        counterparty: null,
        trader: null,
      };
    },
    deleteCptyTrader(data) {
      const self = this;
      this.$swal({
        title:
          this.$t("settings.cpty_trader.messages.sure_delete_cpty_trader") +
          ` ${data.Trader}`,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "settings.cpty_trader.buttons.yes_delete_it"
        ),
        cancelButtonText: this.$t("settings.cpty_trader.buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("settings/deleteCptyTrader", {
              id: data.id,
            })
            .then((res) => {
              if (res.data.info == true) {
                self.getTraders();
                self.loading = false;
                self.$swal.fire({
                  icon: "success",
                  title: "Deleted!",
                  text: this.$t(
                    "settings.cpty_trader.messages.cpty_trader_deleted"
                  ),
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                });
              } else {
                this.cptyNotFoundMessage();
                self.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    editCpty(data) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getCounterparties`)
          .then((res) => {
            this.optCptyTraders = res.data;
            this.cptyTraderModal = true;
            this.status = "Edit";
            this.oldTraderName = data.Trader;
            this.cptyTraderData = {
              id: data.id,
              counterparty: data.Counterparty,
              trader: data.Trader,
            };
          })
          .catch((error) => reject(error))
          .finally(() => {
            this.loading = false;
          });
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "settings.cpty_trader.messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingIntermediaryMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.cpty_trader.messages.exist_cpty_trader"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cptyNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.cpty_trader.messages.cpty_trader_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-sm .vgt-table {
  font-size: 0.875rem; /* Küçük yazı tipi boyutu */
}
.table-sm .vgt-table th,
.table-sm .vgt-table td {
  padding: 0.25rem 0.5rem; /* Küçük dolgu */
}
.table-sm .vgt-group-header {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
.table-sm .vgt-pagination {
  font-size: 0.875rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
